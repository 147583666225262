const updateViewportHeight = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
updateViewportHeight();

// listen to the resize event
window.addEventListener('resize', () => {
  updateViewportHeight();
});

// listen to the orientation change event
window.addEventListener('orientationchange', () => {
  updateViewportHeight();
});
